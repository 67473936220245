import LayoutFillImg from "../../../Misc/LayoutFillImg/LayoutFillImg";
import { useRouter } from "next/router";

import styles from "./ResultsShowcase.module.scss";
import Button from "../../../Misc/button/Button";
const ResultsShowcase = () => {
  const router = useRouter();
  return (
    <section className={styles.creativeContainer}>
      <div className={styles.creativeSubContainer}>
        <h2 className={styles.creativeHeading}>
          Design Gallery - An Assortment of our AI Designs
        </h2>
        <h4 className={styles.creativesubHeading}>
          Unlock creative possibilities{" "}
        </h4>
        <p className={styles.creativeDisc}>
          Our AI redesigns any space through evaluating architectural elements,
          detecting the desired area, understanding preferred design styles and
          adhering to your color preferences & text instructions.
        </p>
        <div className={styles.creativeBtns}>
          <Button
            variant="primary"
            onClick={() => router.push("/business/get-access")}
            className={styles.creativePrimaryBtn}
          >
            Get Contractor Access
          </Button>
          <Button
            variant="secondary"
            onClick={() => router.push("/free/free-editor")}
            className={styles.creativeSecondaryBtn}
          >
            Try For Free
          </Button>
        </div>
      </div>

      <div className={styles.creativeBoxContainer}>
        {/* Room Box 1 */}
        <div className={styles.creativeBox}>
          <LayoutFillImg src="/assets/creative1.svg" alt="creative1" />

          <div className={styles.creativeBoxData}>
            <h3 className={styles.creativeBoxheading}>Used inputs</h3>
            <ul className={styles.creativeBoxList}>
              <li className={styles.creativeBoxListItem}>Outdoor living</li>
              <li className={styles.creativeBoxListItem}>Swimming pool</li>
              <li className={styles.creativeBoxListItem}>Garden</li>
              <li className={styles.creativeBoxListItem}>Concrete pathways</li>
              <li className={styles.creativeBoxListItem}>
                Firepit with seating
              </li>
            </ul>
          </div>
        </div>

        {/* Room Box 2 */}
        <div className={styles.creativeBox}>
          <LayoutFillImg src="/assets/creative2.svg" alt="creative2" />

          <div className={styles.creativeBoxData}>
            <h3 className={styles.creativeBoxheading}>Used inputs</h3>
            <ul className={styles.creativeBoxList}>
              <li className={styles.creativeBoxListItem}>Dining</li>
              <li className={styles.creativeBoxListItem}>Bohemian</li>
              <li className={styles.creativeBoxListItem}>Farmhouse</li>
              <li className={styles.creativeBoxListItem}>Scandinavian</li>
              <li className={styles.creativeBoxListItem}>
                Brown, beige, gray, white
              </li>
              <li className={styles.creativeBoxListItem}>
                Earthy greens, yellow, white
              </li>
            </ul>
          </div>
        </div>

        {/* Room Box 3 */}
        <div className={styles.creativeBox}>
          <LayoutFillImg src="/assets/creative3.svg" alt="creative3" />

          <div className={styles.creativeBoxData}>
            <h3 className={styles.creativeBoxheading}>Used inputs</h3>
            <ul className={styles.creativeBoxList}>
              <li className={styles.creativeBoxListItem}>Bathroom</li>
              <li className={styles.creativeBoxListItem}>French country</li>
              <li className={styles.creativeBoxListItem}>Farmhouse</li>
              <li className={styles.creativeBoxListItem}>Glam</li>
              <li className={styles.creativeBoxListItem}>
                Northern sky, blue, coffee
              </li>
              <li className={styles.creativeBoxListItem}>
                Earthy greens, gray, yellow
              </li>
            </ul>
          </div>
        </div>

        {/* Room Box 4 */}
        <div className={styles.creativeBox}>
          <LayoutFillImg src="/assets/creative4.svg" alt="creative4" />

          <div className={styles.creativeBoxData}>
            <h3 className={styles.creativeBoxheading}>Used inputs</h3>
            <ul className={styles.creativeBoxList}>
              <li className={styles.creativeBoxListItem}>Bedroom</li>
              <li className={styles.creativeBoxListItem}>Bohemian</li>
              <li className={styles.creativeBoxListItem}>Farmhouse</li>
              <li className={styles.creativeBoxListItem}>Industrial</li>
              <li className={styles.creativeBoxListItem}>Coral, turquoise</li>
              <li className={styles.creativeBoxListItem}>Blue, white, brown</li>
            </ul>
          </div>
        </div>

        {/* Room Box 5 */}
        <div className={styles.creativeBox}>
          <LayoutFillImg src="/assets/creative5.svg" alt="creative5" />

          <div className={styles.creativeBoxData}>
            <h3 className={styles.creativeBoxheading}>Used inputs</h3>
            <ul className={styles.creativeBoxList}>
              <li className={styles.creativeBoxListItem}>Kitchen</li>
              <li className={styles.creativeBoxListItem}>Coastal</li>
              <li className={styles.creativeBoxListItem}>Modern</li>
              <li className={styles.creativeBoxListItem}>Farmhouse</li>
              <li className={styles.creativeBoxListItem}>
                Yellow, blue, white
              </li>
              <li className={styles.creativeBoxListItem}>
                Earthy greens, gray, yellow
              </li>
            </ul>
          </div>
        </div>

        {/* Room Box 6 */}
        <div className={styles.creativeBox}>
          <LayoutFillImg src="/assets/creative6.svg" alt="creative6" />

          <div className={styles.creativeBoxData}>
            <h3 className={styles.creativeBoxheading}>Used inputs</h3>
            <ul className={styles.creativeBoxList}>
              <li className={styles.creativeBoxListItem}>Kids room</li>
              <li className={styles.creativeBoxListItem}>Glam</li>
              <li className={styles.creativeBoxListItem}>Modern</li>
              <li className={styles.creativeBoxListItem}>
                Earthy greens, gray, yellow
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ResultsShowcase;
